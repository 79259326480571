import { SampleGetAllQueryFilter, SamplePostExportCSVRequest, SamplePostExportCSVResponse } from 'dto'

import { API_URL } from './DataProvider'
import { post } from './requester'

/**
 * 全件取得が重すぎるので、数件ずつの取得をループさせる
 */
export async function fetchSampleExportCsv(
  ids: number[],
  filters: SampleGetAllQueryFilter
): Promise<SamplePostExportCSVResponse> {
  const queryBodyBase: SamplePostExportCSVRequest = {
    ids: ids,
    filters: filters,
    _perPage: 100, // 決め打ちで100
  }

  let _page = 1
  let csv = ''
  for (;;) {
    const { data } = await post<SamplePostExportCSVResponse>(`${API_URL}/samples/export_csv`, {
      ...queryBodyBase,
      _page,
    })
    const rows = data.csv.replace(/^[^\r\n]*(\r?\n)?/, '')

    if (rows === '') {
      if (_page === 1) {
        csv = data.csv
      }
      break
    }

    if (_page === 1) {
      csv = data.csv
    } else {
      csv = `${csv}\r\n${rows}`
    }
    _page++
  }
  return {
    data: {
      csv,
      id: 1,
    },
  }
}
